
import { Vue, Component, Prop } from 'vue-property-decorator';
import NumberFilter from '@/components/DataTable/DataTableFilter/NumberFilter.vue';
import { symbolStringToSymbol } from '@/helpers/converters/currency';
import { Filter } from '@/types/ListTypes';

@Component({
  name: 'CurrencyFilter',
  components: {
    NumberFilter,
  },
})
export default class CurrencyFilter extends Vue {
  @Prop({ required: true })
  private readonly filter!: Filter;

  protected getCurrencySymbol(): string {
    return symbolStringToSymbol((this.filter.metadata?.currency ?? 'EUR') as string);
  }
}
